import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import antd_ptBR from 'antd/lib/locale-provider/pt_BR';
import dateFns_PtBR from 'date-fns/locale/pt-BR';
import { registerLocale } from 'react-datepicker';
import { QueryClientProvider, QueryClient } from 'react-query';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '@/store';

import Container from '@/container';

import GlobalStyles from '@/styles/global';
import 'react-datepicker/dist/react-datepicker.css';
import 'antd/dist/antd.less';

import * as serviceWorker from './serviceWorker';
import { CardContextProvider } from './Context/CardContext';
import { ModalInfoContextProvider } from './Context/ModalInfoContext';

registerLocale('pt-BR', dateFns_PtBR);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

ReactDOM.render(
  <ConfigProvider locale={antd_ptBR}>
    <Provider store={store}>
      <ModalInfoContextProvider>
        <CardContextProvider>
          <PersistGate persistor={persistor}>
            <QueryClientProvider client={queryClient}>
              <GlobalStyles />
              <Container />
            </QueryClientProvider>
          </PersistGate>
        </CardContextProvider>
      </ModalInfoContextProvider>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root'),
);
serviceWorker.unregister();
