/* eslint-disable no-shadow */
/* eslint-disable radix */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { cpfCnpjFormatter } from '@/utils/formatters';

import { changeActivePartnerSE } from '@/store/slices/partners';
import { getCompanyAccount } from '@/api/requests';
import { CustomAutoComplete } from './styles';

const { Option } = CustomAutoComplete;

const ChangePartner = () => {
  const { all = [], active = {} } = useSelector(state => state.partnersReducer);
  const {
    name = '',
    account = '',
    companyId = '',
    nationalRegister = '',
    accounts = [],
  } = active;
  const dispatch = useDispatch();
  const [activeName, setActiveName] = useState('');
  const [useDescription, setDescription] = useState(
    accounts.length > 0 ? accounts[0].description : '',
  );
  const [results, setResults] = useState([]);

  useEffect(() => {
    const company = async () => {
      if (companyId !== '') {
        const dataCompanyCard = await getCompanyAccount(companyId);
        const { companyAccounts } = dataCompanyCard;
        if (companyAccounts !== null) {
          const accountData = companyAccounts.find(
            tem => parseInt(tem.idAccount) === parseInt(account),
          );
          const { description } = accountData;
          setDescription(description);
        } else {
          setDescription('');
        }
      }
    };
    company();
  }, [active]);

  useEffect(() => {
    let nome = `${name} - ${cpfCnpjFormatter(nationalRegister)} - ${account}`;
    if (useDescription) {
      nome += ` - ${useDescription}`;
    }
    setActiveName(nome);
    setResults(all);
  }, [name, all, active, account, useDescription]);

  const handleSearch = searchTerm => {
    setActiveName(searchTerm);

    if (!searchTerm) {
      setResults(all);
      return;
    }
    setResults(
      all.filter(
        item =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.nationalRegister.includes(searchTerm),
      ),
    );
  };

  const handleBlur = () => {
    const partnerNames = all.map(item => item.name);

    if (!partnerNames.includes(activeName)) {
      setActiveName(
        `${name} - ${nationalRegister} - ${account} - ${useDescription}` || '',
      );
    }
  };

  const handleFocus = event => {
    event.target.select();
  };

  const handleSelect = (value, option) => {
    const { key } = option;
    if (value !== activeName) {
      dispatch(changeActivePartnerSE({ partner: key }));
      setActiveName(value);
      window.location.reload(false);
    }
  };

  return (
    <CustomAutoComplete
      placeholder="Nenhum parceiro selecionado"
      value={activeName}
      onChange={handleSearch}
      onSelect={handleSelect}
      onBlur={handleBlur}
      onFocus={handleFocus}
      defaultActiveFirstOption={false}
    >
      {results.length > 0 &&
        results.map(item => {
          const { accounts } = item;
          let descriptionValue;
          if (accounts.length > 0) {
            const accountData = accounts.find(
              tem => parseInt(tem.account) === parseInt(item.account),
            );
            const { description } = accountData;
            descriptionValue = ` - ${description}`;
          } else {
            descriptionValue = '';
          }
          return (
            <Option
              key={item.nationalRegister}
              value={`${item.name} - ${item.account} - ${cpfCnpjFormatter(
                item.nationalRegister,
              )}`}
            >
              {item.name} - {cpfCnpjFormatter(item.nationalRegister)} -{' '}
              {item.account}
              {descriptionValue}
            </Option>
          );
        })}
    </CustomAutoComplete>
  );
};

export default ChangePartner;
