import React from 'react';
import { Button, Tooltip } from 'antd';
import { IoMdRefresh } from 'react-icons/io';

const RefreshButton = () => {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Tooltip title="Atualizar página">
      <Button type="text" onClick={refreshPage} style={{ padding: 0 }}>
        <IoMdRefresh size={28} color="#007881" />
      </Button>
    </Tooltip>
  );
};

export default RefreshButton;
