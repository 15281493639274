import React, { lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import CustomRoute from '@/routes/components/CustomRoute';

const EscolhaParceiro = lazy(() => import('@/pages/app/EscolhaParceiro'));
const Dashboard = lazy(() => import('@/pages/app/Dashboard'));
const DepositoDireto = lazy(() => import('@/pages/app/DepositoDireto'));
const TrocoDigital = lazy(() => import('@/pages/app/TrocoDigital'));
const DetalhamentoCheque = lazy(() =>
  import('@/pages/app/TrocoDigital/DetalhamentoCheque'),
);

const Tranferecia = lazy(() => import('@/pages/app/Transferencia'));
const TrocoDigitalParceiro = lazy(() =>
  import('@/pages/app/TrocoDigitalParceiro'),
);
const SaqueParceiro = lazy(() => import('@/pages/app/SaqueParceiro'));
const Saque = lazy(() => import('@/pages/app/Saque'));
const ImprimirCupom = lazy(() => import('@/pages/app/ImprimirCupom'));
const UploadArquivoCnab = lazy(() => import('@/pages/app/ProcessoCnab'));
const DetalhamentoErroCnab = lazy(() =>
  import('@/pages/app/ProcessoCnab/DetalhamentoErro'),
);
const RelatorioDeposito = lazy(() => import('@/pages/app/RelatorioDeposito'));
const RelatorioSaque = lazy(() => import('@/pages/app/RelatorioSaque'));
const RelatorioTrocoDigital = lazy(() =>
  import('@/pages/app/RelatorioTrocoDigital'),
);
const ListagemUsuarios = lazy(() =>
  import('@/pages/app/Usuarios/ListagemUsuarios'),
);
const CadastroUsuario = lazy(() =>
  import('@/pages/app/Usuarios/CadastroUsuario'),
);
const EdicaoUsuario = lazy(() => import('@/pages/app/Usuarios/EdicaoUsuario'));
const ListagemEmpresas = lazy(() =>
  import('@/pages/app/Empresas/ListagemEmpresas'),
);
const CadastroEmpresa = lazy(() =>
  import('@/pages/app/Empresas/CadastroEmpresa'),
);
const EdicaoEmpresa = lazy(() => import('@/pages/app/Empresas/EdicaoEmpresa'));
const ListagemProfiles = lazy(() =>
  import('@/pages/app/Profiles/ListagemProfiles'),
);
const CadastroProfile = lazy(() =>
  import('@/pages/app/Profiles/CadastroProfile'),
);
const EdicaoProfile = lazy(() => import('@/pages/app/Profiles/EdicaoProfile'));
const LimiteOperacoes = lazy(() =>
  import('@/pages/app/LimiteOperacoes/ListagemLimiteOperacoes'),
);
const CadastroLimiteOperacoes = lazy(() =>
  import('@/pages/app/LimiteOperacoes/CadastroLimite'),
);

const RelatorioTransacoesFinanceiro = lazy(() =>
  import('@/pages/app/RelatorioTransacoesFinanceiro'),
);

const RelatorioTransacoesPosto = lazy(() =>
  import('@/pages/app/RelatorioTransacoesPosto'),
);

const RelatorioTransacoesPDV = lazy(() =>
  import('@/pages/app/RelatorioTransacoesPDV'),
);

const VincularCupomDeposito = lazy(() =>
  import('@/pages/app/RelatorioTransacoesPDV/components/VincularCupomDeposito'),
);

const Extrato = lazy(() => import('@/pages/app/Extrato'));

const Saldos = lazy(() => import('@/pages/app/Saldo'));

const Deposito = lazy(() => import('@/pages/app/Deposito'));

const DepositoParceiro = lazy(() => import('@/pages/app/DepositoParceiro'));

const AdicionarPessoas = lazy(() => import('@/pages/app/AdicionarPessoas'));

const AdicionarCartoes = lazy(() => import('@/pages/app/AdicionarCartoes'));

const VincularPessoas = lazy(() =>
  import('@/pages/app/AdicionarPessoas/VincularPessoas'),
);

const VincularCartoes = lazy(() =>
  import('@/pages/app/AdicionarCartoes/VincularCartoes'),
);

const AdicionarCartoesPagamentos = lazy(() =>
  import('@/pages/app/AdicionarCartoesPagamentos'),
);

const VincularCartoesPagamentos = lazy(() =>
  import('@/pages/app/AdicionarCartoesPagamentos/VincularCartoes'),
);

const AporteCartoesPagamentos = lazy(() =>
  import('@/pages/app/AdicionarCartoesPagamentos/FazerAporte'),
);

const ExtratoCartaoPagamento = lazy(() =>
  import('@/components/ComponentAboutCard/ExtratoMovimentacoes'),
);

const ExtratoCartaoDespesas = lazy(() =>
  import('@/pages/app/CartaoDespesas/components/Extrato'),
);

const ContasCartoesPagamentos = lazy(() =>
  import('@/pages/app/ContasCartoesPagamentos'),
);

const ReciboExtrato = lazy(() => import('@/pages/app/RelatorioReciboExtrato'));

const ImportacaoCartaoAdicional = lazy(() =>
  import('@/pages/app/ImportacaoCartaoAdicional'),
);

const CartaoDespesa = lazy(() => import('@/pages/app/CartaoDespesas'));

const GrupoEconomico = lazy(() => import('@/pages/app/GrupoEconomico'));

const HorarioPermitido = lazy(() => import('@/pages/app/HorarioPermitido'));

const HorarioPermitidoCadastrar = lazy(() =>
  import('@/pages/app/HorarioPermitido/Create'),
);

const HorarioPermitidoAtualizar = lazy(() =>
  import('@/pages/app/HorarioPermitido/Update'),
);

const IntervencaoTransacao = lazy(() =>
  import('@/pages/app/IntervencaoTransacao'),
);

const AppRoutes = ({ route }) => {
  return (
    <Switch>
      <CustomRoute
        exact
        path="/escolhaParceiro"
        title="Frotabank - Escolha do Parceiro"
        component={EscolhaParceiro}
      />
      <CustomRoute
        exact
        path="/dashboard"
        title="Frotabank - Dashboard"
        component={Dashboard}
      />
      <CustomRoute
        exact
        path="/deposito"
        title="Frotabank - Depósito"
        component={Deposito}
      />
      <CustomRoute
        exact
        path="/depositoParceiro"
        title="Frotabank - Depósito"
        component={DepositoParceiro}
      />
      <CustomRoute
        exact
        path="/ProcessoCnab"
        title="Frotabank - ProcessoCnab"
        component={UploadArquivoCnab}
      />
      <CustomRoute
        exact
        path="/trocoDigital"
        title="Frotabank - Troco Digital"
        component={TrocoDigital}
      />
      <CustomRoute
        exact
        path="/detalhamentoCheque"
        title="Frotabank - Detalhamento do Troco Digital"
        component={DetalhamentoCheque}
      />
      <CustomRoute
        exact
        path="/detalhamentoErro"
        title="Frotabank - Detalhamento Erro Processo Cnab"
        component={DetalhamentoErroCnab}
      />
      <CustomRoute
        exact
        path="/depositoDireto"
        title="Frotabank - Depósito"
        component={DepositoDireto}
      />
      <CustomRoute
        exact
        path="/transferencia"
        title="Frotabank - Transferência"
        component={Tranferecia}
      />
      <CustomRoute
        exact
        path="/saqueParceiro"
        title="Frotabank - Saque"
        component={SaqueParceiro}
      />
      <CustomRoute
        exact
        path="/trocoDigitalParceiro"
        title="Frotabank - Troco Digital"
        component={TrocoDigitalParceiro}
      />
      <CustomRoute
        exact
        path="/saque"
        title="Frotabank - Saque"
        component={Saque}
      />
      <CustomRoute
        exact
        path="/imprimirCupom"
        title="Frotabank - Imprimir Cupom"
        component={ImprimirCupom}
      />
      <CustomRoute
        exact
        path="/relatorios/saque"
        title="Frotabank - Relatório de Saques"
        component={RelatorioSaque}
      />
      <CustomRoute
        exact
        path="/relatorios/deposito"
        title="Frotabank - Relatório de Depósitos"
        component={RelatorioDeposito}
      />
      <CustomRoute
        exact
        path="/relatorios/trocoDigital"
        title="Frotabank - Relatório dos Trocos Digitais"
        component={RelatorioTrocoDigital}
      />
      <CustomRoute
        exact
        path="/relatorios/transacoesFinanceiro"
        title="Frotabank - Transações"
        component={RelatorioTransacoesFinanceiro}
      />
      <CustomRoute
        exact
        path="/relatorios/transacoesPosto"
        title="Frotabank - Transações"
        component={RelatorioTransacoesPosto}
      />
      <CustomRoute
        exact
        path="/relatorios/transacoesPDV"
        title="Frotabank - Transações"
        component={RelatorioTransacoesPDV}
      />
      <CustomRoute
        exact
        path="/relatorios/reciboExtrato"
        title="Frotabank - Solicitações"
        component={ReciboExtrato}
      />
      <CustomRoute
        exact
        path="/cartoes/importarCartao"
        title="Frotabank - Importação Cartão"
        component={ImportacaoCartaoAdicional}
      />
      <CustomRoute
        exact
        path="/relatorios/transacoesPDV/vincularCupom"
        title="Frotabank - Vincular cupom"
        component={VincularCupomDeposito}
      />
      <CustomRoute
        exact
        path="/usuarios"
        title="Frotabank - Usuários"
        component={ListagemUsuarios}
      />
      <CustomRoute
        exact
        path="/usuarios/cadastro"
        title="Frotabank - Cadastro de Usuário"
        component={CadastroUsuario}
      />
      <CustomRoute
        exact
        path="/usuarios/edicao"
        title="Frotabank - Edição do Usuário"
        component={EdicaoUsuario}
      />
      <CustomRoute
        exact
        path="/empresas"
        title="Frotabank - Empresas"
        component={ListagemEmpresas}
      />
      <CustomRoute
        exact
        path="/empresas/cadastro"
        title="Frotabank - Cadastro de Empresa"
        component={CadastroEmpresa}
      />
      <CustomRoute
        exact
        path="/empresas/edicao"
        title="Frotabank - Edição da Empresa"
        component={EdicaoEmpresa}
      />
      <CustomRoute
        exact
        path="/profiles"
        title="Frotabank - Profiles"
        component={ListagemProfiles}
      />
      <CustomRoute
        exact
        path="/profiles/cadastro"
        title="Frotabank - Cadastro de Profile"
        component={CadastroProfile}
      />
      <CustomRoute
        exact
        path="/profiles/edicao"
        title="Frotabank - Edição da Profile"
        component={EdicaoProfile}
      />
      <CustomRoute
        exact
        path="/extratos"
        title="Frotabank - Extratos"
        component={Extrato}
      />
      <CustomRoute
        exact
        path="/configuracoes/limiteOperacoes"
        title="Frotabank - Limite Operações"
        component={LimiteOperacoes}
      />
      <CustomRoute
        exact
        path="/configuracoes/grupoEconomico"
        title="Frotabank - Grupo Economico"
        component={GrupoEconomico}
      />
      <CustomRoute
        exact
        path="/limiteOperacoes/cadastro"
        title="Frotabank - Limite Operações"
        component={CadastroLimiteOperacoes}
      />
      <CustomRoute
        exact
        path="/saldo"
        title="Frotabank - Saldos"
        component={Saldos}
      />
      <CustomRoute
        exact
        path="/deposito"
        title="Frotabank - Depósito"
        component={Deposito}
      />
      <CustomRoute
        exact
        path="/adicionais/pessoas"
        title="Frotabank - Adicionar pessoas"
        component={AdicionarPessoas}
      />
      <CustomRoute
        exact
        path="/adicionais/pessoas/vincular"
        title="Frotabank - Adicionar pessoas"
        component={VincularPessoas}
      />
      <CustomRoute
        exact
        path="/adicionais/cartoes"
        title="Frotabank - Listar Cartões"
        component={AdicionarCartoes}
      />
      <CustomRoute
        exact
        path="/adicionais/cartoes/vincular"
        title="Frotabank - Adicionar Cartões"
        component={VincularCartoes}
      />

      <CustomRoute
        exact
        path="/adicionais/cartoesPagamentos"
        title="Frotabank - Cartões Pagamento"
        component={AdicionarCartoesPagamentos}
      />

      <CustomRoute
        exact
        path="/cartaoDespesas"
        title="Frotabank - Cartões Despesas"
        component={CartaoDespesa}
      />

      <CustomRoute
        exact
        path="/adicionais/cartoesPagamentos/vincular"
        title="Frotabank - Cartões de Pagamento"
        component={VincularCartoesPagamentos}
      />

      <CustomRoute
        exact
        path="/adicionais/cartoesPagamentos/aporte"
        title="Frotabank - Fazer novo aporte"
        component={AporteCartoesPagamentos}
      />

      <CustomRoute
        exact
        path="/adicionais/cartoesPagamentos/extrato"
        title="Frotabank - Extrato"
        component={ExtratoCartaoPagamento}
      />

      <CustomRoute
        exact
        path="/cartaoDespesas/components/extrato"
        title="Frotabank - Extrato"
        component={ExtratoCartaoDespesas}
      />

      <CustomRoute
        exact
        path="/contasCartoesPagamentos"
        title="Frotabank - Contas cartões de pagamentos"
        component={ContasCartoesPagamentos}
      />

      <CustomRoute
        exact
        path="/acesso/horarioPermitido"
        title="Frotabank - Acessos"
        component={HorarioPermitido}
      />

      <CustomRoute
        exact
        path="/acesso/horarioPermitido/cadastrar"
        title="Frotabank - Acessos"
        component={HorarioPermitidoCadastrar}
      />

      <CustomRoute
        exact
        path="/acesso/horarioPermitido/atualizar"
        title="Frotabank - Acessos"
        component={HorarioPermitidoAtualizar}
      />
      <CustomRoute
        exact
        path="/intervencao/transacoes"
        title="Frotabank - Lista transacoes"
        component={IntervencaoTransacao}
      />
      <CustomRoute
        exact
        path="/consultas/chequeTroco"
        title="Frotabank - Consulta Cheque Troco"
        component={() => {
          window.open('https://rebrand.ly/awd1kxp', '_blank');
          return null;
        }}
      />
      <CustomRoute
        exact
        path="/consultas/cartaFrete"
        title="Frotabank - Consulta Carta Frete"
        component={() => {
          window.open('https://rebrand.ly/1tk6ouf', '_blank');
          return null;
        }}
      />
      <CustomRoute
        title="Frotabank"
        component={() => <Redirect to={route} />}
      />
    </Switch>
  );
};

export default AppRoutes;
