import React from 'react';
import { HiOfficeBuilding, HiCreditCard } from 'react-icons/hi';
import {
  IoAccessibility,
  IoBriefcase,
  IoCash,
  IoCashOutline,
  IoCloudUpload,
  IoConstruct,
  IoHomeSharp,
  IoNewspaperOutline,
  IoPeople,
  IoLink,
  IoWalletSharp,
  IoCardOutline,
  IoAccessibilityOutline,
  IoBuildOutline,
} from 'react-icons/io5';

const links = [
  {
    id: '/dashboard',
    title: 'Dashboard',
    icon: <IoHomeSharp size={18} />,
    link: '/dashboard',
  },
  {
    id: '/saldo',
    title: 'Saldos',
    icon: <IoWalletSharp size={18} />,
    link: '/saldo',
  },
  {
    id: '/extratos',
    title: 'Extratos',
    icon: <IoNewspaperOutline size={18} />,
    link: '/extratos',
  },
  {
    id: '/transferencia',
    title: 'Transferência',
    icon: <IoCashOutline size={18} />,
    link: '/transferencia',
  },
  {
    id: '/saque',
    title: 'Saque',
    icon: <IoCashOutline size={18} />,
    link: '/saque',
  },
  {
    id: '/deposito',
    title: 'Depósito',
    icon: <HiCreditCard size={18} />,
    link: '/deposito',
  },
  {
    id: '/depositoDireto',
    title: 'Depósito',
    icon: <HiCreditCard size={18} />,
    link: '/depositoDireto',
  },
  {
    id: '/depositoParceiro',
    title: 'Depósito',
    icon: <HiCreditCard size={18} />,
    link: '/depositoParceiro',
  },
  {
    id: '/saqueParceiro',
    title: 'Saque',
    icon: <IoCashOutline size={18} />,
    link: '/saqueParceiro',
  },
  {
    id: '/trocoDigitalParceiro',
    title: 'Troco Digital',
    icon: <IoCash size={18} />,
    link: '/trocoDigitalParceiro',
  },
  {
    id: '/trocoDigital',
    title: 'Troco Digital',
    icon: <IoCash size={18} />,
    link: '/trocoDigital',
  },
  {
    id: '/consultas',
    title: 'Consultas',
    icon: <IoLink size={18} />,
    link: '/consultas',
    subItems: [
      {
        id: '/consultas/chequeTroco',
        title: 'Cheque troco',
        link: '/consultas/chequeTroco',
      },
      {
        id: '/consultas/cartaFrete',
        title: 'Carta frete',
        link: '/consultas/cartaFrete',
      },
    ],
  },
  {
    id: '/ProcessoCnab',
    title: 'Processo CNAB',
    icon: <IoCloudUpload size={18} />,
    link: '/ProcessoCnab',
  },
  {
    id: 'relatorios',
    title: 'Relatórios',
    icon: <HiOfficeBuilding size={18} />,
    link: '/relatorios',
    subItems: [
      {
        id: '/relatorios/saque',
        title: 'Saque',
        link: '/relatorios/saque',
      },
      {
        id: '/relatorios/deposito',
        title: 'Depósito',
        link: '/relatorios/deposito',
      },
      {
        id: '/relatorios/trocoDigital',
        title: 'Troco Digital',
        link: '/relatorios/trocoDigital',
      },
      {
        id: '/relatorios/transacoesFinanceiro',
        title: 'Transacões',
        link: '/relatorios/transacoesFinanceiro',
      },
      {
        id: '/relatorios/transacoesPosto',
        title: 'Transacões',
        link: '/relatorios/transacoesPosto',
      },
      {
        id: '/relatorios/transacoesPDV',
        title: 'Transacões',
        link: '/relatorios/transacoesPDV',
      },
      {
        id: '/relatorios/reciboExtrato',
        title: 'Solicitações',
        link: '/relatorios/reciboExtrato',
      },
    ],
  },
  {
    id: '/empresas',
    title: 'Empresas',
    icon: <IoBriefcase size={18} />,
    link: '/empresas',
  },
  {
    id: '/cartaoDespesas',
    title: 'Cartões de Despesas',
    icon: <HiCreditCard size={18} />,
    link: '/cartaoDespesas',
    subItems: [
      {
        id: '/cartaoDespesas',
        title: 'Cartões',
        icon: <HiCreditCard size={18} />,
        link: '/cartaoDespesas',
      },
      {
        id: '/cartoes/importarCartao',
        title: 'Importação de cartão',
        link: '/cartoes/importarCartao',
      },
    ],
  },
  {
    id: '/acesso',
    title: 'Acesso',
    icon: <IoAccessibilityOutline size={18} />,
    link: '/acesso',
    subItems: [
      {
        id: '/usuarios',
        title: 'Usuários',
        icon: <IoPeople size={18} />,
        link: '/usuarios',
      },
      {
        id: '/profiles',
        title: 'Profiles',
        icon: <IoAccessibility size={18} />,
        link: '/profiles',
      },
      {
        id: '/acesso/horarioPermitido',
        title: 'Horários Permitidos',
        link: '/acesso/horarioPermitido',
      },
    ],
  },
  {
    id: '/LimiteOperacoes',
    title: 'Limite Operações',
    icon: <IoConstruct size={18} />,
    link: '/limiteOperacoes',
  },
  {
    id: '/configuracoes',
    title: 'Configurações',
    icon: <IoConstruct size={18} />,
    link: '/configuracoes',
    subItems: [
      {
        id: '/configuracoes/limiteOperacoes',
        title: 'Limite Operações',
        link: '/configuracoes/limiteOperacoes',
      },
      {
        id: '/configuracoes/grupoEconomico',
        title: 'Grupos Econômico',
        link: '/configuracoes/grupoEconomico',
      },
    ],
  },
  {
    id: '/intervencao',
    title: 'Intervenção',
    icon: <IoBuildOutline size={18} />,
    link: '/intervencao',
    subItems: [
      {
        id: '/intervencao/transacoes',
        title: 'Transações',
        link: '/intervencao/transacoes',
      },
    ],
  },
  {
    id: '/adicionais',
    title: 'Cartões de Despesas',
    icon: <HiCreditCard size={18} />,
    link: '/adicionais',
    subItems: [
      {
        id: '/adicionais/cartoesPagamentos',
        title: 'Por Aporte',
        link: '/adicionais/cartoesPagamentos',
      },
      {
        id: '/adicionais/cartoes',
        title: 'Por Limite',
        link: '/adicionais/cartoes',
      },
      {
        id: '/adicionais/pessoas',
        title: 'Pessoas',
        link: '/adicionais/pessoas',
      },
      {
        id: '/contasCartoesPagamentos',
        title: 'Contas auxiliares',
        icon: <IoCardOutline size={18} />,
        link: '/contasCartoesPagamentos',
      },
    ],
  },
];

export default links;
