import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  .ant-layout-sider.ant-layout-sider-light.ant-layout-sider-has-trigger {
    border-right: 1px solid #efefef;

    .ant-layout-sider-trigger {
      border-right: 1px solid #efefef;
    }
  }

  .ant-menu-item {
    font-weight: 600;
    height: 50px;

    &:not(.ant-menu-item-selected) {
      color: #b1b0b5;
    }

    &.ant-menu-item-only-child {
      height: 50px;
    }
  }

  .ant-menu-submenu {
    font-weight: 600;

    .ant-menu-submenu-title {
      height: 50px;
    }

    &:not(.ant-menu-item-selected) {
      color: #b1b0b5;
    }
  }

  .ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.ant-menu-inline-collapsed {
    svg {
      margin-top: 12px;
    }
  }
`;

export const Logo = styled(Link)`
  margin: 16px 16px 16px 5px;
  height: 50px;
  border-radius: 6px;
  background: #f3f3f4;
  transition: 0.3s;
  justify-content: center;
  display: flex;
  align-items: center;
  min-width: 50px;
  > img {
    height: 32px;
  }
`;
