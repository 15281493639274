const PAGE_TITLES = {
  '/dashboard': 'Dashboard',
  '/depositoDireto': 'Depósito',
  '/trocoDigital': 'Troco Digital',
  '/detalhamentoTrocoDigital': 'Detalhamento do troco digital',
  '/historico': 'Histórico',
  '/transferencia': 'Transferência',
  '/saque': 'Saque',
  '/relatorios/deposito': 'Relatório de Depósitos',
  '/relatorios/saque': 'Relatório de Saques',
  '/relatorios/trocoDigital': 'Relatório dos Trocos Digitais',
  '/usuarios': 'Usuários',
  '/usuarios/cadastro': 'Cadastro de Usuário',
  '/usuarios/edicao': 'Edição do Usuário',
  '/empresas': 'Empresas',
  '/empresas/cadastro': 'Cadastro de Empresa',
  '/empresas/edicao': 'Edição da Empresa',
  '/profiles': 'Profiles',
  '/profiles/cadastro': 'Cadastro de Profile',
  '/profiles/edicao': 'Edição da Profile',
  '/extratos': 'Extratos',
  '/limiteOperacoes': 'Limite de Operações',
  '/saldo': 'Saldos',
  '/deposito': 'Depósito',
  '/depositoParceiro': 'Depósito',
  '/relatorios/transacoesFinanceiro': 'Relatório Transações',
  '/relatorios/transacoesPosto': 'Relatório Transações',
  '/relatorios/transacoesPDV': 'Relatório Transações',
  '/relatorios/reciboExtrato ': 'Relatório Solicitações',
  '/consultas/chequeTroco': 'Cheque troco',
  '/consultas/cartaFrete': 'Carta frete',
  '/adicionais/pessoas': 'Adicionar Pessoas',
  '/adicionais/pessoas/vincular': 'Novo cadastro de pessoa adicional',
  '/adicionais/cartoes': 'Cartões Adicionais',
  '/adicionais/cartoes/vincular': 'Cartão de despesas por limite',
  '/relatorios/transacoesPDV/vincularCupom': 'Vincular cupom depósito',
  '/adicionais/cartoesPagamentos': 'Cartão de despesas por aporte',
  '/adicionais/cartoesPagamentos/vincular': 'Cartão de despesas por aporte',
  '/adicionais/cartoesPagamentos/aporte': 'Fazer novo aporte',
  '/adicionais/cartoesPagamentos/extrato': 'Extrato de movimentações',
  '/contasCartoesPagamentos': 'Contas para cartões de pagamentos',
  '/cartaoDespesas': 'Cartões de Despesas',
  '/acesso/horarioPermitido': 'Acesso',
  '/acesso/horarioPermitido/cadastrar': 'Cadastrar  horas permitidas',
  '/acesso/horarioPermitido/atualizar': 'Atualizar  horas permitidas',
  '/cartoes/importarCartao': 'Importação cartão',
};

export const getPageTitle = pathname => {
  const pageTitle = PAGE_TITLES[pathname];
  return pageTitle;
};
