import React from 'react';
import { Button, notification } from 'antd';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { FiLogOut } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import useViewportWidth from '@/hooks/useViewportWidth';
import { logoutSE } from '@/store/slices/user';

import MobileSidebar from '@/components/TopHeader/components/MobileSidebar';
import ChangePartner from '@/components/TopHeader/components/ChangePartner';
import UserName from '@/components/TopHeader/components/UserName';
import RefreshButton from '@/components/TopHeader/components/RefreshButton';

import { TopHeaderContainer } from './styles';

const TopHeader = () => {
  const dispatch = useDispatch();

  const { push } = useHistory();

  const queryClient = useQueryClient();

  const { isMobile } = useViewportWidth();

  const logoutConfirmation = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        onClick={() => {
          notification.close(key);
          queryClient.clear();

          dispatch(logoutSE());

          push('/login');
        }}
      >
        Sim, eu tenho certeza
      </Button>
    );

    notification.open({
      message: 'Saída',
      description: 'Tem certeza que deseja sair?',
      btn,
      key,
    });
  };

  return (
    <TopHeaderContainer>
      <ChangePartner />

      {isMobile ? (
        <MobileSidebar />
      ) : (
        <div className="nav-items">
          <RefreshButton />

          <UserName />

          <Button
            type="text"
            onClick={() => logoutConfirmation()}
            style={{ padding: 0 }}
          >
            <FiLogOut size={20} color="#999c9e" />
          </Button>
        </div>
      )}
    </TopHeaderContainer>
  );
};

export default TopHeader;
