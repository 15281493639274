import React from 'react';
import { PageHeader } from 'antd';
import { useLocation } from 'react-router-dom';

import { getPageTitle } from '@/components/Header/constants';

const Header = () => {
  const { pathname } = useLocation();

  return (
    <PageHeader
      className="page-header"
      title={getPageTitle(pathname)}
      ghost={false}
      backIcon={false}
    />
  );
};

export default Header;
