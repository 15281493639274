import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';

import useViewportWidth from '@/hooks/useViewportWidth';

import AppRoutes from '@/routes/app.routes';

import TopHeader from '@/components/TopHeader';
import Sidebar from '@/components/Sidebar';
import Footer from '@/components/Footer';
import Header from '@/components/Header';

const AppLayout = () => {
  const { all } = useSelector(state => state.partnersReducer);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { pathname } = useLocation();
  let hasOneCompany = '/escolhaParceiro';
  const { isMobile } = useViewportWidth();

  const showLayoutComponents = pathname !== '/escolhaParceiro';
  const showSidebar = showLayoutComponents && !isMobile;

  if (all.length === 1) hasOneCompany = '/dashboard';

  const toggleSidebar = () => {
    setIsSidebarOpen(prevState => !prevState);
  };

  return (
    <Layout>
      {showSidebar && (
        <Sidebar isOpen={isSidebarOpen} toggleOpen={toggleSidebar} />
      )}

      <Layout
        style={
          showSidebar
            ? {
                marginLeft: isSidebarOpen ? 200 : 80,
                transition: 'margin 0.2s',
                minHeight: '100vh',
                background: '#f8f8f8',
              }
            : { minHeight: '100vh', background: '#f8f8f8' }
        }
      >
        <Layout.Content
          style={{
            margin: showLayoutComponents ? '0 16px' : 0,
          }}
        >
          {showLayoutComponents && <TopHeader />}
          {showLayoutComponents && <Header />}
          <AppRoutes route={hasOneCompany} />
        </Layout.Content>
        {showLayoutComponents && <Footer />}
      </Layout>
    </Layout>
  );
};

export default AppLayout;
